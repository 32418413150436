.project-details{
   position:fixed;
   top:50%;
   left:50%;
   transform: translate(-50%,-60%);
    width:90%;
    max-width:1100px;
    height:80%;
    max-height:880px;
    background-color:rgba(255,255,255,0.99);
    border-radius:25px;
    overflow: hidden;
animation: openDetails 1s ease-in-out 0s 1 ;
}
.project-details > p {
    padding:10px;
    font-size:25px;
    font-weight:400;
}

.project-details > div {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: space-around;
}

.project-details > div > a {
    padding:10px;
    background-color:rgba(0,0,0,0.2);
    border-radius:999px;
    font-weight:700;
}

a{
    color:inherit;
    text-decoration: none;
}

.icons{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin:10px;
    padding:15px;
    border-top:1px solid grey;
    background-color: rgba(0,0,0,0.05);
}
.icons >button {
    color:red;
  
}

.hosting-icon{
    color:green;
}
.btn{
    background-color: rgba(0,0,0,0) !important;
    border-radius:2px !important;
    padding:10px 20px; 
}
.project-details > img {
    width:100%;
    height:350px;

}
.skills{
    padding:0px 20px;
}
@media (max-width:768px){
    .project-details > img {
        width:100%;
        height:230px;
    
    }
}
@keyframes openDetails{
    0%{
       opacity:0.3;
       transform: scale(0.4) translate(-70%,-60%);
    }
    30%{
       opacity:1;
       transform: scale(1) translate(-50%,-60%);
    }
    100%{
     opacity:1;
    }
}