.card{
width:180px;
margin:30px 10px;
border-radius:15px;
background:rgb(0, 0, 0);
color:rgb(255, 255, 255);
overflow:hidden;
transition: 0.2s;
cursor:pointer;
box-shadow: rgba(0, 0, 0, 0.69) 0px 26px 30px -10px,
rgba(0, 0, 0, 0.73) 0px 16px 10px -10px;
transition: all 250ms cubic-bezier(0.25, 0.46, 0.165, 1) 0s;
border: 3px solid rgba(249, 249, 249, 0.1);
  overflow:hidden;
height:180px;
}


  .card > p{
    padding:6px 4px;
    overflow:hidden;
  }



.card >img{
  display: none;
width:200px;
height:110px;
}
.card >h3{
  width:100%;
  text-align: center;
  margin:auto;
  padding:7px;

}

  .card:hover{
    box-shadow: rgba(0, 0, 0, 0.69) 0px 26px 30px -10px,
    rgba(0, 0, 0, 0.73) 0px 16px 10px -10px;
  transform: scale(1.05);
  border-color: rgba(249, 249, 249, 0.8);
  }