.footer{
    position :fixed;
    width:300px;
   left: calc(50% - 160px);
   padding:5px 20px;
    bottom:30px;
    background:whitesmoke;
    border-radius:50px;
    box-shadow: 2px 3px 2px 0px rgba(0,0,0,0.2),2px 1px 2px 2px rgba(0,0,0,0.3);
    display:flex;
    align-items: center;
   justify-content: center;
   opacity:0.6;
   transition:0.5s;
}
.footer:hover{
    opacity:1;
}
.social > button {
    width:70px;
    height:70px;
    border-radius: 50%;
}
.github-icon{
    color:rgb(1,1,1);
    font-size:40px !important;
}
.linked-in-icon{
font-size:40px !important;
}

.separate{
width:3px;
height:60px;
border-radius:1px;
margin:0 20px;
background:rgb(255, 255, 255);
box-shadow: 1px 2px 1px 0px rgba(0,0,0,0.1),1px 1px 1px 1px rgba(0,0,0,0.2);
}



.email-icon{
    font-size:30px !important;
}
.contact-me > button {
    width:70px;
    height:70px;
    border-radius: 50%;
}
a{
    text-decoration: none;
    color:inherit;
}