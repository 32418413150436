.hero-section{
    width:100%;
    background-color: var(---hero-color);
}
.hero-content{
  display:flex;
  align-items: center;
  justify-content: center;
  color:white;
  width:1047px;
  height:400px;
  transform: translateX(-140px);
  margin:auto;
}
.hero-right{
  margin-left:30px;
}
.hero-left{
    display:flex;
    align-items:flex-start;
    justify-content: flex-start;
    flex-direction: column;

    padding:10px;
    margin:auto;
    margin-left:20px;
   background-color: transparent;
  
}
.divider{
width:3px;
height:300px;
background-color: rgba(255,255,200,0.4);
}
@media(max-width:900px){
  .divider{
    display: none;
  }
}
.hero-right > h1,.hero-right > h4{
   display: flex;
   align-items: center;
   justify-content: flex-start;
  font-weight: 200;
}
.hero-right > h4 >a{
  margin-left:10px;
  transition: 0.4;
}
.hero-right > h4 >a:hover{
  text-decoration: underline;
}
.hero-image{
    padding:10px;
}

.hero-image >img{
    width:200px;
 
   border-radius:25px;
}


.hero-text{
    padding:20px;

    max-height:250px;
}
.hero-text > p {
    font-size:var(--hero-text_size);
    letter-spacing:2.5px;
    line-height:30px;
}

.hero-text::-webkit-scrollbar {
    width: 10px;

  }
  
  /* Track */
  .hero-text::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);
    border-radius:5px 5px 5px 5px;
  }
  
  /* Handle */
  .hero-text::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.5);
    border-radius:5px 5px 5px 5px;
    cursor:pointer;
  }
  
  /* Handle on hover */
  .hero-text::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,0.8);
  }



@media  (max-width:768px){
    .hero-content{
        margin-top:70px;
        width:380px;
        flex-direction:column;
    }
}
h1 {
  font-size:50px;
  margin:5px;
  font-weight: 300;
}
h4{
  font-size:20px;
}
p{
  font-size:15px;
}