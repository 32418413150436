.skill-porto{
    width:80%;
    height:320px;
    display:flex;
    align-items: center;
   justify-content: center;
    color:white;
    margin-top:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  margin-top:45px;
 }
 .skill-porto-content{
     width:85%;
     height:75%;
     border-radius:10px;

     transition: 0.3s;
     overflow-y: scroll;

 }
 .skill-title >img{
     width:28px;
 }
 @media (min-width:1000px){
    .skill-porto-content{
        overflow-y: hidden;
    }
 }
 @media (max-width:768px){
.skill-porto-content{
      overflow-y: scroll;
}
.skill-title >h3{
    display:none;
}
.skill-title{
    padding:2px 0px;
}
.skill-title>img{
    width:20px;
}
 }
 @media (max-width:640px){
    .skill-title>img{
        width:26px;
    }
 }
 
 .skill-title{
     display: flex;
     align-items: center;
     justify-content: center;
     padding:0px 10px;
     background-color: rgba(255,255,255,0.05);
     border-radius:10px 10px 0 0 ;
     cursor:pointer;
     transition: 0.3s;

 }
 .skill-title >h3 {
    font-weight: 200;
    font-size:14px;
 }
 .skill-title:hover{
   
 }
 .skill-porto-projects{
  width:100%;
     display:flex;
     align-items: flex-start;
     justify-content: center;
     flex-wrap: wrap;
     padding:3px;
     gap:50px;
 
 }
 
 
 
 
 
 .skill-porto-content::-webkit-scrollbar {
     width: 10px;
 
   }
   
   /* Track */
   .skill-porto-content::-webkit-scrollbar-track {
     background: rgba(255,255,255,0.1);
     border-radius:5px 5px 5px 5px;
   }
   
   /* Handle */
   .skill-porto-content::-webkit-scrollbar-thumb {
     background: rgba(255,255,255,0.5);
     border-radius:5px 5px 5px 5px;
     cursor:pointer;
   }
   
   /* Handle on hover */
   .skill-porto-content::-webkit-scrollbar-thumb:hover {
     background: rgba(255,255,255,0.8);
   }
 .skill-porto-projects-section{
  width: 100%;
   display:flex;
   align-items: center;
   justify-content: space-between;
 }
 .controller-left,.controller-right{
   height:60px !important;
   width:50px !important;
   background-color: rgba(0,0,0,0.2) !important;

 }

 @media (max-width:768px){
    .skill-porto-projects-section{
align-items: center;
    }
 }
 .controller-left{
  border-radius:50% !important;
 }
 .controller-right{
border-radius:50% !important;
 }
 .skill-porto > .tags {
    width: 70%;
    display:flex;
   align-items: center;
   justify-content: flex-start;
   gap: 8px;
 }
