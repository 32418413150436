.emailForm{
padding:20px;
background:rgba(60, 60, 60, 0.85);
border-radius:45px;

box-shadow:0px 1px 2px 1px rgba(0,0,0,0.2);
position :fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width:70%;
  max-width:400px;
  height:80%;
  max-height:500px;
  z-index:2000;
  border:none;
  animation: show 0.3s ease 0s 1;

}
.emailForm > form{
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
}
.emailForm >form textarea{
    
    resize:none;
    border:none;
    outline:none;
    width:90%;
    height:35%;
    margin:20px;
    border-radius:5px;
    background-color: rgba(240,240,240,0.8);
    padding:10px;




}



.emailForm >form textarea::-webkit-scrollbar {
    width: 10px;

  }
  
  /* Track */
  .emailForm >form textarea::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);
    border-radius:5px 5px 5px 5px;
  }
  
  /* Handle */
  .emailForm >form textarea::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.5);
    border-radius:5px 5px 5px 5px;
    cursor:pointer;
  }
  
  /* Handle on hover */
  .emailForm >form textarea::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,0.8);
  }

.emailForm >form textarea:focus{
 
    background-color: rgb(255, 255, 255);

}

.emailForm >form input{
    margin:20px;
    width:80%;
    outline:none;
    border:none;
    padding:15px 30px;
    border-radius:999px;
    background-color: rgba(240,240,240,0.8);
}
.emailForm >form input:focus{
    background-color: white;
}
.emailForm >form button{
    width:80%;
    margin:8px;
    border-radius:7px;
    background-color: rgb(4, 202, 90);
    color:white;
    cursor:pointer;
    padding:10px 0px;
    outline:none;
    border:none;
font-size:18px;
font-weight:400;
}
.emailForm >form button:hover{
   
    background-color: rgb(2, 255, 111);
 

}
.closeBtn{
    background-color: rgb(202, 4, 4) !important;
}
.closeBtn:hover{
    background-color: rgb(230, 7, 7) !important;
}

@keyframes show{
    0%{
        opacity:0.4;
        transform: scale(0.8) translate(-60%,-50%);
       
    }
    100%{
        opacity:1;
        transform: scale(1) translate(-50%,-60%);
    } 
}