.popUp{
    position:fixed;
    top:20px;
    left:50%;
    transform: translate(-50%,0);
    animation: showPopUp 2s ease 0s 1;
    width:90%;
    max-width:400px;
}
.message-body{
    width:100%;
    padding:20px 30px;
    background-color: rgba(255,255,255,1);
    border-radius:999px;
    display:flex;
    align-items: center;
    justify-content: center;

}
.message-body >img{
    width:24px;
    border-radius: 50%;
    margin:10px;
}
@keyframes showPopUp {
    0%{
        opacity:0.6;
        transform:translate(-50%,-30px);
    }
    20%{
        opacity:1;
        transform:translate(-50%,0);
    }
    50%{
        opacity:1;
        transform:translate(-50%,0);
    }
    100%{
        opacity:0;
        transform:translate(-50%,-20px) scale(0.6);
    }
}