.cover{
    width:100%;
}
.project-page{
    color:white;
}
hr{
    width:100%;
}
.description{
margin:30px auto;
    background-color: rgba(0,0,0,0.6);
    width:75%;
    padding:20px;
display: flex;
align-items: flex-start;
justify-content: start;
flex-direction: column;
font-size:18px;
}
img{
    width:100%;
}
.skills{
    display:flex;
    align-items:baseline;
    
    justify-content: flex-start;
}

.challenges{
    display:flex;
    align-items:baseline;
    
    justify-content: flex-start;
}
.video-demo{
    display:flex;
    align-items:baseline;
    
    justify-content: flex-start;
}
.line{
    width:95%;
    height:5px;
    border-radius: 100px;
    margin-left:10px;
    background-color: rgba(255,255,255,0.4);
}

.skills-container{
    background-color: rgba(0,0,0,0.6);
    padding:20px;
    display:flex;
    align-items:center;
    justify-content: space-around;
}
.demo-container{
    background-color: rgba(0,0,0,0.6);
    padding:30px;
    display:flex;
    align-items:center;
    justify-content: space-around;
}
.challenges-container{
    background-color: rgba(0,0,0,0.6);
    padding:20px;
   
}
.skills-container > div  {
 transition: 0.5s;

}
.skills-container > div:hover{
    color:  rgb(124, 182, 237);

}
.skills-container > div:hover > .skill-underline{
  width:100%;

}
.skill-item{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.skill-underline{
    background-color: rgb(124, 182, 237);
    margin:3px;
    width:0;
    height:2px;
    border-radius: 10px;
    transition: 0.2s;
}

.header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:10px;
}
.github-project-icon{
  transition: 0.4s !important;
 font-size: 50px !important;
 margin-left: 20px !important;
}
.github-project-icon:hover{
    color:rgb(0, 107, 230);
    transform: scale(1.1);
  }

  .website-icon{
    transition: 0.4s !important;
   font-size: 50px !important;
   margin-left: 20px !important;
  }
  .website-icon:hover{
      color:rgb(0, 107, 230);
      transform: scale(1.1);
    }

.back-to-home{
    width: fit-content;
    height:fit-content;
    padding:5px 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 209, 63);
    position: fixed;
    left:-15px;
    bottom:30px;
    border-radius: 0 10px 10px 0;
    transition: 0.2s;
    cursor: pointer;
}
.home-icon{
    font-size: 30px !important;
    margin-left: 10px !important;
}
.back-to-home:hover{
    width: fit-content;
    height:fit-content;
    padding:5px 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 209, 63);
    position: fixed;
    left:0;
    bottom:30px;
    border-radius: 0 10px 10px 0;
}